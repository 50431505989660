<template>
  <div>
    <el-col :span="24">
      <div class="title">{{ $t('unitPage.recordLogsBtn') }}</div>
    </el-col>
    <el-col :span="24" class="search-box" v-if="isSupply">
      <el-form ref="form" :model="formSearch" class="form-box">
        <el-form-item class="flex-row" :label="$t('unitPage.typeSelect')">
          <el-select
            v-model="optionsValType"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="changeType"
          >
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="flex-row margin-to"
          :label="$t('unitPage.statusSelect')"
        >
          <el-select
            v-model="optionsValStatus"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="changeStatus"
          >
            <el-option
              v-for="item in optionsStatus"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" class="search-box" v-else>
      <el-form ref="form" :model="formSearch" class="form-box">
        <el-form-item class="flex-row" :label="$t('unitPage.typeSelect')">
          <el-select
            v-model="optionsValType"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="changeType"
          >
            <el-option
              v-for="item in optionsInvestType"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="flex-row margin-to"
          :label="$t('unitPage.statusSelect')"
        >
          <el-select
            v-model="optionsValStatus"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="changeStatus"
          >
            <el-option
              v-for="item in optionsStatus"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
    <el-table :data="dataList">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column :label="$t('tableI18n.Type')" prop="type" align="center">
        <template v-slot="scope">{{ scope.row.typeText }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('tableI18n.AppliedAmountDollars')"
        prop="token"
        align="center"
      >
        <template v-slot="scope">
          {{ scope.row.token ? Number(scope.row.token).toFixed(2) : '0.00' | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('tableI18n.RealAmountDollars')"
        prop="token"
        align="center"
      >
        <template v-slot="scope">
            <span v-if="scope.row.status === 3">
              <span v-if="scope.row.type === 1">{{ scope.row.token ? Number(scope.row.actualAmount).toFixed(2) : '0.00' | toThousandFilterTwo }}</span>
              <span v-if="scope.row.type === 2">{{ scope.row.token ? Number(scope.row.token).toFixed(2) : '0.00' | toThousandFilterTwo }}</span>
            </span>
            <span v-else>0.00</span>
        </template>
      </el-table-column>
      <!-- <el-table-column :label="$t('unitPage.unit')" prop="token" align="center">
        <template
          v-slot="scope"
        >{{ (scope.row.type === 1 || scope.row.type === 4) ? '+' : '-' }}{{ scope.row.token ? Number(scope.row.token).toFixed(2) : '0.00' }}</template>
      </el-table-column> -->
      <el-table-column
        :label="$t('tableI18n.DateofProposal')"
        prop="createdAt"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.createdAt }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('unitPage.receivingBank')"
        prop="receivingBank"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.receivingBank }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('unitPage.bankAccountNumber')"
        prop="bankAccountNumber"
        align="center"
      >
        <template v-slot="scope">{{
          scope.row.bankAccountNumber
        }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('unitPage.accountHolderName')"
        prop="accountHolderName"
        align="center"
      >
        <template v-slot="scope">{{
          scope.row.accountHolderName
        }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('unitPage.swiftCode')"
        prop="swiftCode"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.swiftCode }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('tableI18n.TransactionRemarkCode')"
        prop="transferCode"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.transferCode }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('tableI18n.Status')"
        prop="status"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.statusText }}</template>
      </el-table-column>
      <el-table-column
        :label="$t('tableI18n.Notes')"
        prop="remard"
        show-overflow-tooltip
        align="center"
      >
        <template v-slot="scope">{{
          scope.row.remard ? scope.row.remard : '--'
        }}</template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import AdPagination from '@/components/ad-pagination';
import filter from '@/filters';
export default {
  name: 'record-logs',
  components: {
    AdPagination,
  },
  data() {
    return {
      isSupply: false,
      process: this.$enums.PROCESS,
      formSearch: {},
      optionsType: this.$enums.UNIT_RECORD_SUPPLIER_TYPE,
      optionsInvestType: this.$enums.UNIT_RECORD_INVEST_TYPE,
      optionsValType: '',
      optionsStatus: this.$enums.UNIT_RECORD_STATUS,
      optionsValStatus: '',
      totalAmount: 0,
      dataList: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      tableLoading: false,
      tableEmpty: this.$t('loading'),
    };
  },
  mounted() {
    this.isSupply = this.$route.path.includes('supply');
    this.getDataList();
  },
  methods: {
    changeType() {
      this.getDataList();
    },
    changeStatus() {
      this.getDataList();
    },
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getDataList();
    },
    getDataList() {
      this.tableLoading = true;
      const data = {
        type: this.optionsValType,
        status: this.optionsValStatus,
        page: this.tablePageCP,
        pageSize: this.tablePagePS,
      };
      if (this.optionsValType === '100' || !this.optionsValType) {
        delete data.type;
      }
      if (this.optionsValStatus === '100' || !this.optionsValStatus) {
        delete data.status;
      }
      this.$axios
        .get('/v1/user/token-orders', { params: data })
        .then((response) => {
          if (response.code === 0 || response.code === '0') {
            this.dataList = response.data.rows || [];
            this.tableTotal = response.data.count || 0;
            this.tableLoading = false;
            this.dataList.map((item) => {
              item.typeText = filter.unitType(item.type.toString());
              item.statusText = filter.unitStatus(item.status.toString());
            });
          } else {
            this.tableLoading = false;
            this.$message({ type: 'error', message: response.message });
          }
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  margin-bottom: 22px;
}
::v-deep .form-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  .el-form-item {
    margin-bottom: 0;
  }
  .margin-to {
    margin-left: 40px;
  }
}
</style>
